import "./App.css";
import Confetti from "./Confetti";

const App = () => {
  return (
    <div className="App">
      <Confetti />
      <header className="App-header">
        <h1 style={{ marginBottom: "0px" }}>量化资本VC.SB</h1>
        <p style={{ marginTop: "10px", marginBottom: "50px" }}>量化你的资本</p>
        <div>
          <a
            target="_blank"
            href={"mailto:a@vc.sb"}
            class="fa-brands fa-gg-circle"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            target="_blank"
            href={"https://args.cn"}
            class="fa-brands fa-weixin"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            target="_blank"
            href={"https://t.me/vc_sb"}
            class="fa-brands fa-telegram"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
        </div>
      </header>
    </div>
  );
};

export default App;
